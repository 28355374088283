<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("department.index") }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-simple-table
        dense
        fixed-header
        style="border: 1px solid #aaa"
        :height="screenHeight"
        class="ma-1"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th rowspan="2" class="text-center" style="max-width: 50px">#</th>
              <th rowspan="2" class="text-center">
                {{ $t("department.index") }}
              </th>
              <th colspan="7" class="text-center">
                {{ $t("document.outbox") }}
              </th>
              <!-- <th class="text-center">{{ $t("document.inbox") }}</th> -->
            </tr>
            <tr>
              <th class="text-center">{{ $t("reportDepartment.all") }}</th>
              <th class="text-center">
                {{ $t("reportDepartment.published") }}
              </th>
              <th class="text-center">
                {{ $t("reportDepartment.processing") }}
              </th>
              <th class="text-center">{{ $t("reportDepartment.signed") }}</th>
              <th class="text-center">{{ $t("reportDepartment.ready") }}</th>
              <th class="text-center">
                {{ $t("reportDepartment.completed") }}
              </th>
              <th class="text-center">
                {{ $t("reportDepartment.cancelled") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="text-center">
                {{
                  items
                    .map(function(x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1
                }}
              </td>
              <td>
                <router-link :to="'/reports/department/' + item.id">{{
                  item["name_" + $i18n.locale]
                }}</router-link>
              </td>
              <td class="text-center">{{ item.create_documents }}</td>
              <td class="text-center">{{ item.published }}</td>
              <td class="text-center">{{ item.processing }}</td>
              <td class="text-center">{{ item.signed }}</td>
              <td class="text-center">{{ item.ready }}</td>
              <td class="text-center">{{ item.completed }}</td>
              <td class="text-center">{{ item.cancelled }}</td>
            </tr>
            <tr v-if="department">
              <td class="text-center">{{ items.length + 1 }}</td>
              <td>{{ department["name_" + $i18n.locale] }}</td>
              <td class="text-center">{{ department.create_documents }}</td>
              <td class="text-center">{{ department.published }}</td>
              <td class="text-center">{{ department.processing }}</td>
              <td class="text-center">{{ department.signed }}</td>
              <td class="text-center">{{ department.ready }}</td>
              <td class="text-center">{{ department.completed }}</td>
              <td class="text-center">{{ department.cancelled }}</td>
            </tr>
            <tr v-if="all">
              <td class="text-center" colspan="2">
                {{ all["name_" + $i18n.locale] }}
              </td>
              <td class="text-center">{{ all.create_documents }}</td>
              <td class="text-center">{{ all.published }}</td>
              <td class="text-center">{{ all.processing }}</td>
              <td class="text-center">{{ all.signed }}</td>
              <td class="text-center">{{ all.ready }}</td>
              <td class="text-center">{{ all.completed }}</td>
              <td class="text-center">{{ all.cancelled }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading: false,
      items: [],
      department: 0,
      all: {}
    };
  },
  watch: {
    $route(to, from) {
      this.getList();
    }
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 130;
    },
    language() {
      return this.$i18n.locale == "ru" ? "uz_cyril" : this.$i18n.locale;
    },
    headers() {
      return [
        // { text: "", value: "data-table-expand" },
        { text: "#", value: "id", align: "center", width: 30 },
        { text: this.$t("department.index"), value: "department" },
        { text: this.$t("report.create_document"), value: "create_document" },
        { text: this.$t("report.expired"), value: "expired" },
        { text: this.$t("report.prosesing"), value: "prosesing" },
        { text: this.$t("report.waiting"), value: "waiting" },
        // { text: this.$t("document.drafts"), value: "draft" },
        {
          text: this.$t("report.completed_on_time"),
          value: "completed_on_time"
        },
        { text: this.$t("report.failed_in_time"), value: "failed_in_time" }
      ];
    }
  },
  methods: {
    toggleExpand($event) {},
    rowClick(item, row) {
      row.expand(!row.isExpanded);
      // if (this.expanded[0] && this.expanded[0].id == item.id) this.expanded = [];
      // else this.expanded = [item];
    },
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/reports/department", {
          parent_id: this.$route.params.parent_id
            ? this.$route.params.parent_id
            : 0
        })
        .then(response => {
          this.items = response.data.departments;
          this.department = response.data.department;
          this.all = response.data.all;
          // this.items = this.items.sort(function (a, b) {
          //   return b.expired - a.expired;
          // });
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getList();
  },
  created() {}
};
</script>

<style scoped>
table tbody tr td {
  white-space: normal !important;
}
</style>
